
import axios from 'axios';
export default {

    async createDriverBagOrders(params) {
        return await axios.post(`driver_bag_orders/create`, params)
    },
    async createDriverBagOrdersList(params) {
        return await axios.post(`driver_bag_orders/create/list`, params)
    },
    async updateDriverBagOrdersColumn(column, value, data) {
        return await axios.put(`driver_bag_orders/update_list?${column}=${value}`, data)
    },
    async updateDriverBagOrdersByIDsListStatus(list, status) {
        return await axios.put(`driver_bag_orders/update_list_status`, {
            list: list,
            status: status
        })
    },
    async deleteDriverBagOrdersList(list) {
        return await axios.delete(`driver_bag_orders/delete_list`, {
            data: {
                list: list
            }
        })
    },
    async reportDriverBagOrders(column, value) {
        return await axios.get(`driver_bag_orders/report?${column}=${value}`)
    },
    async getAllDriverBagOrders() {
        return await axios.get(`driver_bag_orders/all`)
    },
    async getOneDriverBagOrders(driver_bag_order_id) {
        return await axios.get(`driver_bag_orders/all/${driver_bag_order_id}`)
    },
    async getDriverBagOrdersByColumn(column, value) {
        return await axios.get(`driver_bag_orders/filter?column=${column}&value=${value}`)
    },
    async deleteDriverBagOrders(driver_bag_order_id) {
        return await axios.delete(`driver_bag_orders/delete/${driver_bag_order_id}`)
    },
    async updateDriverBagOrders(driver_bag_order_id, params) {
        return await axios.put(`driver_bag_orders/update/${driver_bag_order_id}`, params)
    }
}